import { mappls, mappls_plugin} from  'mappls-web-maps';
import configData from "../config.json";

function  Marker() {
const  styleMap  = {width:  '99%', height:  '99vh', display:'inline-block'}
const  mapProps  = { center: [28.6330, 77.2194], traffic:  false, zoom:  4, geolocation:  false, clickableIcons:  false }
var mapObject , markerObject;
var mapplsClassObject=  new  mappls();
var mapplsPluginObject =  new  mappls_plugin();


var  geoData=
{
  "type":  "FeatureCollection",
  "features": 
    [
      {
        "type":  "Feature",
        "properties":
          {
            "description":"noida",
            "icon":"https://apis.mapmyindia.com/map_v3/1.png",
          },
        "geometry": 
          {
            "type":  "Point",
            "coordinates": [28.544,77.5454]
          }
      },
      {
        "type":  "Feature",
        "properties": 
          {
            "description":"faridabad",
            "icon":"https://apis.mapmyindia.com/map_v3/1.png"
          },
        "geometry": 
          {
            "type":  "Point",
            "coordinates": [28.27189158,77.2158203125]
          }
      },
      {
        "type":  "Feature",
        "properties": 
          {
            "description":"delhi",
            "icon":"https://apis.mapmyindia.com/map_v3/1.png"
          },
        "geometry": 
          {
            "type":  "Point",
            "coordinates": [28.549511,77.2678250]
          }
      }
    ]
};


	mapplsClassObject.initialize(configData.MAP_TOKEN,()=>{
		mapObject = mapplsClassObject.Map({id:  "map", properties: mapProps});
		
		
var  geoData=
{
  "type":  "FeatureCollection",
  "features": 
    [
      {
        "type":  "Feature",
        "properties": 
          {
            "description":"delhi",
            "icon":"https://apis.mapmyindia.com/map_v3/1.png"
          },
        "geometry": 
          {
            "type":  "Point",
            "coordinates": [28.544, 77.5454]
          }
      },
      {
        "type":  "Feature",
        "properties": 
          {
            "description":"delhi",
            "icon":"https://apis.mapmyindia.com/map_v3/1.png"
          },
        "geometry": 
          {
            "type":  "Point",
            "coordinates": [28.27189158, 77.2158203125]
          }
      },
      {
        "type":  "Feature",
        "properties": 
          {
            "description":"delhi",
            "icon":"https://apis.mapmyindia.com/map_v3/1.png"
          },
        "geometry": 
          {
            "type":  "Point",
            "coordinates": [28.549511, 77.2678250]
          }
      }
    ]
};

markerObject = mapplsClassObject.addGeoJson({
	 map:mapObject,
    data:geoData,
    fitbounds:false,
    cType:0
});

		//load map layers/components after map load, inside this callback (Recommended)
		mapObject.on("load", ()=>{
		// Activites after mapload


		})

	});



return (
<div>
<div  id="map"  style={styleMap}></div>
</div>
);
}
export  default  Marker;