import { mappls, mappls_plugin} from  'mappls-web-maps';
import configData from "../config.json";
import axios from 'axios';


function  SimpleMarker() {


const  styleMap  = {width:  '99%', height:  '99vh', display:'inline-block'}
const  mapProps  = { center: [28.61, 77.23] , traffic:  false, zoom:  10, geolocation:  false, clickableIcons:  false }
var mapObject , markerObject;
var mapObject1 , markerObject1;
var mapplsClassObject=  new  mappls();

function hello(time){
    alert(time);
}

async function getLatLong(imei){

    const constURL = `${configData.API_URL_LOCAL}/api/livemap/1/${imei}`;
   
     return await axios.get(constURL)
       .then(function (response) {
       return response;
     })
     .catch(function (error) {
       console.log(error);
     });
       
   }
   

async function getAddressLatLng(latData, lngData){

    return latData && lngData &&	await axios.get(`https://apis.mapmyindia.com/advancedmaps/v1/a286b4d1e97a8430ffcfad807b986156/rev_geocode?lat=${latData}&lng=${lngData}`)
    .then(function (response) {
        console.log(response.data.results[0].formatted_address,"api");
      return response.data.results[0].formatted_address;
    })
    .catch(function (error) {
      console.log(error);
    });

}
  



mapplsClassObject.initialize(configData.MAP_TOKEN,()=>{
            mapObject = mapplsClassObject.Map({id:  "map", properties: mapProps});

            markerObject = mapplsClassObject.Marker({
                    map:  mapObject,
                    position: {
                        "lat": 28.519467,
                        "lng": 77.223150
                    }
                    ,fitbounds:  false, // Set View in which marker's plotted,
                    icon:  'https://apis.mapmyindia.com/map_v3/2.png', // icon url or Path

            });

            markerObject1 = mapplsClassObject.Marker({
                    map:  mapObject,
                    position: {
                        "lat": 28.519467,
                        "lng": 77.223150
                    }
                    ,fitbounds:  false, // Set View in which marker's plotted,
                    icon:  'https://maps.mapmyindia.com/images/to.png', // icon url or Path

            });

            mapObject.on("load", ()=>{
                let index=0;
                let index1=40;
                let data=null;
                setInterval(async() => {
                    const response =  await getLatLong(865167041567968);
                     markerObject.setPosition({lat:response.data.data[index].lat,lng:response.data.data[index].lng});
                     markerObject.setIcon("http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png");
                
                }, 5000);
                // setInterval(async() => {
                //     const latlong =  await getLatLong(index1,865167041567968,1);
                //     latlong && markerObject1.setPosition({lat:latlong.lat,lng:latlong.lng});
                //     index1--; 
                // }, 10000);  

                mapObject.addListener('click', async function() {

                   const mapAddress = await getAddressLatLng(28.519467,77.223150);

                   console.log(mapAddress,"api");


                    markerObject.setPopup(`${mapAddress}`,{openPopup:true});
                   markerObject.setIcon("http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png");
                });
            });


});



return (
<div>
<div  id="map"  style={styleMap}></div>
<div  id="map1"  style={styleMap}></div>
</div>
);
}
export  default  SimpleMarker;