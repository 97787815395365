import React, { useState , useEffect } from 'react';


const CardListNew = () => {
  return (
  <video controls  autoPlay={true}>
  <source
    src="https://d150hyw1dtprld.cloudfront.net/Footage/oWaS1Os1KSaCI5eMEgKMdA/full-preview-e53041f2fb.mp4"
    type="video/mp4"
  />
</video>

  );
};




// Example usage
const SplashScreen = () => {

  return <CardListNew />  ;
};

export default SplashScreen;
