import React, { useState , useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import PolylineMapV2 from './PolylineMapV2'; 
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from 'react-router-dom';
import configData from "../config.json";



function getformattedDate(i) {

      return      i.toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('');
       
    }

async function getLatLong(startDate,endDate,imei){

  const constURL = `${configData.API_URL}/api/mapdata/${startDate}/${imei}`;


  return	axios.get(constURL)
  .then(function (response) {
    console.log(response.data.data);
    return response.data.data;
  })
  .catch(function (error) {
    console.log(error);
  });
	
}

 function  PolylineNew() {
  const [data, setData] = useState();
  const { imei } = useParams(); 

  const startDate = localStorage.getItem('apiSdate') ;
  const endDate = localStorage.getItem('apiEdate');


  useEffect(() => {
    async function getToken() {
     const res = await getLatLong(startDate,endDate,imei);
     setData(res);
    }
     getToken();
 }, [startDate]);


function factorialOf(data) {
   return   (data && data.length ) ? <PolylineMapV2 data={data} /> : `Loading...` ;
}

const factorial = useMemo(() => factorialOf(data), [data]);
	  
return ( 
  <>
     { factorial  }
  </>
    );
}

const cardStyles = {
  container: {
    boxShadow: "0 0 3px 2px #cec7c759",
    alignItems: "center",
    padding: 0,
    borderRadius: 0,
  },
  input:{
    padding:10,
    margin:10,
  },
  button:{
    padding:10,
    margin:10
  }
};

export  default  PolylineNew;