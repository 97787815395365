import React, { useState, useEffect } from 'react';

const Report = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f8f9fa', // Set background color
        color: '#495057', // Set text color
        fontFamily: 'Arial, sans-serif', // Set font family
      }}
    >
      <div
        style={{
          opacity: visible ? 1 : 0,
          transform: visible ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
          textAlign: 'center',
        }}
      >
        <h1 style={{ fontSize: '2.5rem', marginBottom: '10px' }}>Coming Soon!</h1>
        <p style={{ fontSize: '1rem', marginBottom: '20px' }}>
          Our website will be live soon.
        </p>
        {/* Add more content as needed */}
        <button
          style={{
            backgroundColor: '#007bff', // Set button background color
            color: '#fff', // Set button text color
            padding: '10px 20px',
            fontSize: '1rem',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Notify Me
        </button>
      </div>
    </div>
  );
};

export default Report;
