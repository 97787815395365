import React, { Component } from 'react'; 
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'; 
import Marker from './component/Marker'; 
import Polygon from './component/Polygon'; 
import Polyline from './component/Polyline'; 
import PolylineNew from './component/PolylineNew'; 
import LiveLocationNew from './component/LiveLocationNew'; 
import MapFilter from './component/MapFilter';
import UserForm from './component/UserForm';
import AddDevice from './component/AddDevice';
import UserProfile from './component/UserProfile';
import SimpleMarker from './component/SimpleMarker';
import Listing from './component/Listing';
import SplashScreen from './component/SplashScreen';
import Report from './component/report';
import './App.css'; 
import LiveLocation from './component/LiveLocation';



class App extends Component { 
render() { 

	return ( 
	<Router> 
		<div className="App"> 
			
		<Routes> 
				<Route exact path='/' element={<Marker />}></Route> 
				<Route exact path='/Simple' element={<SimpleMarker />}></Route> 
				<Route exact path='/Report' element={<Report />}></Route> 
				<Route exact path='/Listing/:mobile' element={<Listing />}></Route> 
				<Route exact path='/SplashScreen' element={<SplashScreen />}></Route> 
				<Route exact path='/Polygon' element={<Polygon />}></Route> 
				<Route exact path='/UserProfile/:mobile' element={<UserProfile />}></Route> 
				<Route exact path='/UserForm' element={<UserForm />}></Route> 
				<Route exact path='/AddDevice' element={<AddDevice />}></Route> 
				<Route exact path='/Polyline' element={<Polyline />}></Route> 
				<Route exact path='/PolylineNew' element={<PolylineNew />}></Route> 
				<Route exact path='/MapFilter' element={<MapFilter />}></Route> 
				<Route exact path='/MapFilter/:imei' element={<MapFilter />}></Route> 
				<Route exact path='/LiveLocationNew/:type/:imei' element={<LiveLocationNew />}></Route> 
				<Route exact path='/LiveLocation' element={<LiveLocation />}></Route> 
		</Routes> 
		</div> 
	</Router> 
); 
} 
} 

export default App;
