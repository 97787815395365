import React, { useState , useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import axios from 'axios';

import configData from "../config.json";

async function getProfile(mobile){
	
  return	axios.get(`${configData.API_URL}/api/profile/${mobile}`)
    .then(function (response) {

    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  });
	
}

 function UserProfile() {
  const [data, setData] = useState();
  const { mobile } = useParams(); 

  useEffect(() => {

    async function profileData(mobile){
      const res = await getProfile(mobile);
      setData(res[0]);
      console.log(JSON.stringify(data),mobile,"__________");
  
    }
    profileData(mobile)
     
  },[mobile]);



  return ( data &&
    <div style={cardStyles.container}>
    <div className="card">
      <img src="https://www.w3schools.com/w3images/team2.jpg"  />
      <h1>{data.firstName} {data.lastName}</h1>
      <p className="title">{data.mobile} </p>
      <p className="title1">{data.email} </p>
      <p className="title1">{data.city}, {data.state} {data.zip}  </p>
      <div >
      </div>
      {/* <p><button>Contact</button></p> */}
    </div>
    </div>
  );
}

const cardStyles = {
	container: {
	  boxShadow: "0 0 3px 2px #cec7c759",
	  alignItems: "center",
	  padding: 2,
	  borderRadius: 2,
	},
	input:{
	  padding:10,
	  margin:10,
	},
	button:{
	  padding:10,
	  margin:10
	}
  };

export default UserProfile;