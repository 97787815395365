import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'bootstrap';
import PolylineMapV2 from './PolylineMapV2'; 
import configData from '../config.json';

async function getAddressLatLng(latData, lngData){
    return latData && lngData &&	axios.get(`https://apis.mapmyindia.com/advancedmaps/v1/a286b4d1e97a8430ffcfad807b986156/rev_geocode?lat=${latData}&lng=${lngData}`)
    .then(function (response) {
      return response.data.results[0].formatted_address;
    })
    .catch(function (error) {
      console.log(error);
    });
}


async function getLatLong(imei,type){

 const constURL = `${configData.API_URL}/api/livemap/1/${imei}`;

  return	axios.get(constURL)
    .then(function (response) {

    return response.data.data;
  })
  .catch(function (error) {
    console.log(error);
  });
	
}


 function  LiveLocationNew() {

  const [data, setData] = useState();
  const { imei, type} = useParams(); 
	  
	  useEffect(() => {
     async function getToken() {
	   const res = await getLatLong(imei,type);
      setData(res);
     }
     getToken();
    
  }, []);
  


 function getRelaod(){
  window.location.reload(false);
 }
//  setInterval(async () => {
//      const res = await getLatLong(imei,type);
//       setData(res);
//  }, 300000);

   return   (data && data.length ) ? <PolylineMapV2 data={data} imei={imei} /> : `Loading...` ;

}

const cardStyles = {
  container: {
    boxShadow: "0 0 3px 2px #cec7c759",
    alignItems: "center",
    padding: 0,
    borderRadius: 0,
    marginTop:0,
  },
  button: {
    width:'100%',
    color:'#000'
  },
};

export  default  LiveLocationNew;