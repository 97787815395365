import { mappls, mappls_plugin} from  'mappls-web-maps';
function  Polygon() {
const  styleMap  = {width:  '99%', height:  '99vh', display:'inline-block'}
const  mapProps  = { center: [28.6330, 77.2194], traffic:  false, zoom:  4, geolocation:  false, clickableIcons:  false }
var mapObject , markerObject;
var mapplsClassObject=  new  mappls();
var mapplsPluginObject =  new  mappls_plugin();


	mapplsClassObject.initialize("e7c3d5866519d8b609685c78b49245b4",()=>{
		mapObject = mapplsClassObject.Map({id:  "map", properties: mapProps});
		
	

markerObject = mapplsClassObject.Polygon({
	 map:mapObject,
	fillColor:  "red",
fillOpacity:  0.3,
strokeColor:  "red",
strokeOpacity:  0.8,
html:"MMI",
fitbounds:  false,
fitboundOptions: 
	{
		padding: 120,
		duration:1000
	},
popupHtml: 'Route 1',
popupOptions: 
	{
		offset: 
			{
				'bottom': [0, -20]
			}
	},
center:
	{
			"lng":"77.26872",
			"lat":  "28.55101"
		
	},
    paths: [ 
		{
			"lng":"77.26872",
			"lat":  "28.55101"
		},
		{
			"lng":"77.26849",
			"lat":"28.55099"
		},
		{
			"lng":"77.26831",
			"lat":"28.55097"
		},
		{
			"lng":"77.26794",
			"lat":"28.55093"
		},
		{
			"lng":"77.2676",
			"lat":"28.55089"
		},
		{
			"lng":"77.26756",
			"lat":"28.55123"
		},
		{
			"lng":"77.26758",
			"lat":"28.55145"
		},
		{
			"lng":"77.26758",
			"lat":"28.55168"
		},
		{
			"lng":"77.26759",
			"lat":"28.55172"
		}
	]});

		//load map layers/components after map load, inside this callback (Recommended)
		mapObject.on("load", ()=>{
		// Activites after mapload
		})

	});



return (
<div>
<div  id="map"  style={styleMap}></div>
</div>
);
}
export  default  Polygon;