import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import configData from "../config.json";


function AddDevice() {

  const { Formik } = formik;

  const schema = yup.object().shape({
    vehicleType: yup.string().required(),
    vehicleNo: yup.string().required(),
    imeiNo: yup.string().required(),
    mobile: yup.string().required(),
    terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
  });

  async function register(payload){



      return	axios.post(`${configData.API_URL}/api/adddevice`,payload)
      .then(function (response) {

        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
      
    }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={register}
      initialValues={{
        vehicleType: '',
        vehicleNo: '',
        imeiNo: '',
        mobile: '',
        terms: false,
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Control
                type="text"
                name="vehicleType"
                value={values.vehicleType}
                onChange={handleChange}
                isInvalid={!!errors.vehicleType}
                placeholder='Vehicle Type'
              />
                <Form.Control.Feedback type="invalid">
                  {errors.vehicleType}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Control
                type="text"
                name="vehicleNo"
                value={values.vehicleNo}
                onChange={handleChange}
                isInvalid={!!errors.vehicleNo}
                placeholder='Vehicle No'
              />

            <Form.Control.Feedback type="invalid">
            {errors.vehicleNo}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormik02">
            <Form.Control
                type="text"
                name="imeiNo"
                value={values.imeiNo}
                onChange={handleChange}
                isInvalid={!!errors.imeiNo}
                placeholder='IMEI NO'
              />

            <Form.Control.Feedback type="invalid">
            {errors.imeiNo}
            </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Control
                type="text"
                placeholder="Mobile"
                name="mobile"
                value={values.mobile}
                onChange={handleChange}
                isInvalid={!!errors.mobile}
                />

              <Form.Control.Feedback type="invalid">
                {errors.mobile}
              </Form.Control.Feedback>
            </Form.Group>


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>
          <Button type="submit">Submit form</Button>
        </Form>
      )}
    </Formik>
  );
}

export default AddDevice;