import React, { useState , useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import PolylineNew from './PolylineNew'; 
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from 'react-router-dom';
import configData from "../config.json";
import 'react-datepicker/dist/react-datepicker.css';




function getformattedDate(i){
      return      i.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');
       
    }


 function  MapFiler() {

  const [searchParams, setSearchParams] = useSearchParams();

	  let sd= new Date();
  if(localStorage.getItem('sdate')){
    sd=new Date(localStorage.getItem('sdate'));
    
  }   

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate()+1);

  if(localStorage.getItem('edate')){
    tomorrow=new Date(localStorage.getItem('edate'));
    
  }else{
    localStorage.setItem('apiSdate',getformattedDate(sd));
    localStorage.setItem('apiEdate',getformattedDate(tomorrow));  
  }  


	  const [data, setData] = useState();
    const [startDate, setStartDate] = useState(sd);
    const [endDate, setEndDate] = useState(tomorrow);
	  const [filter, setFilter] = useState(1);
    

  const getFilter = async () =>{
    //setSearchParams({ startDate: getformattedDate(startDate) });
   // setSearchParams({ endDate: getformattedDate(endDate) });
   localStorage.setItem('sdate',startDate);
   localStorage.setItem('edate',endDate);
   localStorage.setItem('apiSdate',getformattedDate(startDate));
   localStorage.setItem('apiEdate',getformattedDate(endDate));
    window.location.reload(false);
}
	  
return ( 
      <>
      <div style={cardStyles.container}>
      <div className='row' style={{ width: '100%' }}>
      <div className='col-6'>
      <DatePicker         className="form-control"
      style={{ width: '100%' }}
      dateFormat="dd, MMMM yyyy"

      selected={startDate} onChange={(date) => setStartDate(date)} />

        </div>
        <div className='col-6'>
        
        <button className="btn btn-primary form-control" onClick={getFilter} style={cardStyles.button} >Filter</button>
        
        </div>
      </div>
      <PolylineNew />
      </div>
      </>
    );
}

const cardStyles = {
  container: {
    boxShadow: "0 0 3px 2px #cec7c759",
    alignItems: "center",
    padding: 0,
    borderRadius: 0,
    marginTop:0,
  },
  input: {
    width:'100%',
    color:'green'
}
};

export  default  MapFiler;