import { mappls, mappls_plugin} from  'mappls-web-maps';
import configData from "../config.json";


function  LiveLocation({lat,lng,location,data}) {

const nl= location.split(',');

var d = new Date(data.currentData);
var currentData = d.toLocaleString('en-GB');

var vehicleNo= (data.vehicleNo).replace(/^0+/, '')


const  styleMap  = {width:  '99%', height:  '99vh', display:'inline-block'}
const  mapProps  = { center: [lat, lng], traffic:  false, zoom:  12, geolocation:  false, clickableIcons:  false }
var mapObject , markerObject;
var mapplsClassObject=  new  mappls();
var mapplsPluginObject =  new  mappls_plugin();


	mapplsClassObject.initialize(configData.MAP_TOKEN,()=>{
		mapObject = mapplsClassObject.Map({id:  "map", properties: mapProps});
		
		markerObject = mapplsClassObject.Marker({
		map:  mapObject,
		position:{lat:lat, lng:lng},fitbounds:  false, // Set View in which marker's plotted,
fitboundOptions: 
  { 
    padding:  120, 
    duration:  1000 
  }, //fitbound options
icon:  'https://apis.mapmyindia.com/map_v3/2.png', // icon url or Path
width:  25, // marker's icon width
height:  40, // marker's icon heigh
clusters:  true, //clusters of marker when zoomed out
clustersOptions: 
  { 
    "color":  "blue",
    "bgcolor":  "red" 
  },
offset: [0, 10], 
popupHtml: `<style>::-webkit-scrollbar {width: 10px;} </style><div style=\"max-height:150px;min-width:200px;overflow-y: scroll;\"><p style=\"font:  12px arial helvetica\; margin:1px;">${vehicleNo}</p><p  style=\"font:  12px arial helvetica\; margin:1px;" >${nl[0]}${nl[1]}</p><p  style=\"font:  12px arial helvetica\; margin:1px;" >${nl[2]}</p><p  style=\"font:  12px arial helvetica\; margin:1px;" >${nl[3]}${nl[4]}</p><p  style=\"font:  12px arial helvetica\; margin:1px;">${currentData}</p></div>`,
popupOptions: 
  { 
    openPopup:  true, 
    autoClose:  true, 
    maxWidth:  500
  },
  draggable: false,

		
		});


		mapObject.on("load", ()=>{
	
	})

	});



return (
<div>
<div  id="map"  style={styleMap}></div>
</div>
);
}
export  default  LiveLocation;