import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import axios from 'axios';
import configData from '../config.json';
import 'bootstrap/dist/css/bootstrap.min.css';

const CardList = ({ data }) => {
  return (
    <div className="card-list">
      {data.map((item,index) => (

        <Card key={`lsit-${index}`} data={item} />
      ))}
    </div>
  );
};

const Card = ({ data }) => {
  console.log(data.deviceInfo.vehicleNo,"___________");

    const imgpath=`https://img.freepik.com/premium-vector/set-automotive-logo-vector-cars-dealers-detailing-modification-logo-design-concept-illustration_9692-103.jpg?w=996`;
  return (
    <div className="card m-2" style={{ width: '95vw' }}>
      <img src={imgpath} className="card-img-top" alt={data.vehicleNo} />
      <div className="card-body">
        <h5 className="card-title">{data?.deviceInfo?.vehicleNo}</h5>
        <p className="card-text">{data.currentData}</p>
      </div>
    </div>
  );
};


async function getLatLong(mobile){
	
    return	axios.get(`${configData.API_URL}/api/live/${mobile}`)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
      
  }  

// Example usage
const Listing = () => {

    const [data, setData] = useState();
    const { mobile } = useParams(); 

    console.log(mobile,"_________Mobile_____________");

      useEffect(() => {
        async function getToken() {
          const res = await getLatLong(mobile);
         setData(res);
        }
        getToken();
       
     }, []);
    

  return ( data && <CardList data={data} /> ) ;
};

export default Listing;
