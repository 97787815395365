import { mappls, mappls_plugin} from  'mappls-web-maps';
import configData from '../config.json';

function  Polyline() {

const  styleMap  = {width:  '99%', height:  '99vh', display:'inline-block'}
const  mapProps  = {  
		center: [28.63, 77.42],
        zoomControl: true,
        location: true
		}
var mapObject , polylineObject;
var mapplsClassObject=  new  mappls();
var mapplsPluginObject =  new  mappls_plugin();

mapplsClassObject.initialize(configData.MAP_TOKEN,()=>{
	mapObject = mapplsClassObject.Map({id:  "map", properties: mapProps});
			 
	var pts =[
		{
            "lat": 28.557822,
            "lng": 77.345046
        },
        {
            "lat": 28.557394,
            "lng": 77.350974
        },
        {
            "lat": 28.550119,
            "lng": 77.353706
        },
        {
            "lat": 28.552536,
            "lng": 77.356419
        },
        {
            "lat": 28.550528,
            "lng": 77.354611
        },
        {
            "lat": 28.550999,
            "lng": 77.354979
        },
        {
            "lat": 28.557625,
            "lng": 77.349869
        },
        {
            "lat": 28.558013,
            "lng": 77.349847
        },
        {
            "lat": 28.55803,
            "lng": 77.349775
        },
        {
            "lat": 28.558072,
            "lng": 77.348639
        },
        {
            "lat": 28.557076,
            "lng": 77.355625
        },
        {
            "lat": 28.557193,
            "lng": 77.354473
        },
        {
            "lat": 28.557242,
            "lng": 77.353324
        },
        {
            "lat": 28.558187,
            "lng": 77.347546
        },
        {
            "lat": 28.559024,
            "lng": 77.346377
        },
        {
            "lat": 28.558089,
            "lng": 77.346436
        },
        {
            "lat": 28.557656,
            "lng": 77.345974
        },
        {
            "lat": 28.551147,
            "lng": 77.355632
        },
        {
            "lat": 28.551525,
            "lng": 77.355935
        },
        {
            "lat": 28.55142,
            "lng": 77.356265
        },
        {
            "lat": 28.551462,
            "lng": 77.356316
        },
        {
            "lat": 28.551512,
            "lng": 77.35601
        },
        {
            "lat": 28.548688,
            "lng": 77.351191
        },
        {
            "lat": 28.560056,
            "lng": 77.346148
        },
        {
            "lat": 28.549831,
            "lng": 77.3524
        },
        {
            "lat": 28.557629,
            "lng": 77.346284
        },
        {
            "lat": 28.557863,
            "lng": 77.345549
        },
        {
            "lat": 28.557914,
            "lng": 77.345496
        },
        {
            "lat": 28.557985,
            "lng": 77.345401
        },
        {
            "lat": 28.558071,
            "lng": 77.345198
        },
        {
            "lat": 28.557958,
            "lng": 77.3451
        },
        {
            "lat": 28.557866,
            "lng": 77.345064
        },
        {
            "lat": 28.560699,
            "lng": 77.345678
        },
        {
            "lat": 28.56063,
            "lng": 77.344523
        },
        {
            "lat": 28.560481,
            "lng": 77.34346
        },
        {
            "lat": 28.560312,
            "lng": 77.342443
        },
        {
            "lat": 28.560298,
            "lng": 77.342105
        },
        {
            "lat": 28.560423,
            "lng": 77.341222
        },
        {
            "lat": 28.56078,
            "lng": 77.340307
        },
        {
            "lat": 28.561448,
            "lng": 77.339268
        },
        {
            "lat": 28.56201,
            "lng": 77.338502
        },
        {
            "lat": 28.562722,
            "lng": 77.337557
        },
        {
            "lat": 28.563306,
            "lng": 77.336643
        },
        {
            "lat": 28.563748,
            "lng": 77.335915
        },
        {
            "lat": 28.564231,
            "lng": 77.33519
        },
        {
            "lat": 28.564441,
            "lng": 77.334895
        },
        {
            "lat": 28.564552,
            "lng": 77.334732
        },
        {
            "lat": 28.564627,
            "lng": 77.334662
        },
        {
            "lat": 28.565198,
            "lng": 77.333813
        },
        {
            "lat": 28.56567,
            "lng": 77.333087
        },
        {
            "lat": 28.566115,
            "lng": 77.332354
        },
        {
            "lat": 28.566641,
            "lng": 77.331407
        },
        {
            "lat": 28.569201,
            "lng": 77.327892
        },
        {
            "lat": 28.569762,
            "lng": 77.327154
        },
        {
            "lat": 28.570423,
            "lng": 77.326447
        },
        {
            "lat": 28.570965,
            "lng": 77.3258
        },
        {
            "lat": 28.571626,
            "lng": 77.325127
        },
        {
            "lat": 28.572854,
            "lng": 77.323739
        },
        {
            "lat": 28.573233,
            "lng": 77.323363
        },
        {
            "lat": 28.573598,
            "lng": 77.323083
        },
        {
            "lat": 28.573718,
            "lng": 77.323107
        },
        {
            "lat": 28.573761,
            "lng": 77.323141
        },
        {
            "lat": 28.574403,
            "lng": 77.323842
        },
        {
            "lat": 28.574979,
            "lng": 77.324583
        },
        {
            "lat": 28.575511,
            "lng": 77.325267
        },
        {
            "lat": 28.575891,
            "lng": 77.325772
        },
        {
            "lat": 28.57603,
            "lng": 77.325917
        },
        {
            "lat": 28.576557,
            "lng": 77.326638
        },
        {
            "lat": 28.577095,
            "lng": 77.327344
        },
        {
            "lat": 28.577648,
            "lng": 77.328088
        },
        {
            "lat": 28.578163,
            "lng": 77.328791
        },
        {
            "lat": 28.578688,
            "lng": 77.329525
        },
        {
            "lat": 28.579127,
            "lng": 77.330134
        },
        {
            "lat": 28.57972,
            "lng": 77.330942
        },
        {
            "lat": 28.580389,
            "lng": 77.331827
        },
        {
            "lat": 28.583082,
            "lng": 77.33544
        },
        {
            "lat": 28.583193,
            "lng": 77.335607
        },
        {
            "lat": 28.583706,
            "lng": 77.336319
        },
        {
            "lat": 28.584292,
            "lng": 77.337117
        },
        {
            "lat": 28.584794,
            "lng": 77.337789
        },
        {
            "lat": 28.585366,
            "lng": 77.338556
        },
        {
            "lat": 28.586043,
            "lng": 77.339462
        },
        {
            "lat": 28.586609,
            "lng": 77.34023
        },
        {
            "lat": 28.587157,
            "lng": 77.340975
        },
        {
            "lat": 28.587775,
            "lng": 77.341804
        },
        {
            "lat": 28.588335,
            "lng": 77.342566
        },
        {
            "lat": 28.588946,
            "lng": 77.343392
        },
        {
            "lat": 28.589489,
            "lng": 77.344101
        },
        {
            "lat": 28.589789,
            "lng": 77.344356
        },
        {
            "lat": 28.590795,
            "lng": 77.343606
        },
        {
            "lat": 28.592049,
            "lng": 77.342444
        },
        {
            "lat": 28.592713,
            "lng": 77.341814
        },
        {
            "lat": 28.593249,
            "lng": 77.34132
        },
        {
            "lat": 28.593626,
            "lng": 77.340996
        },
        {
            "lat": 28.593752,
            "lng": 77.340992
        },
        {
            "lat": 28.593799,
            "lng": 77.341019
        },
        {
            "lat": 28.593999,
            "lng": 77.341228
        },
        {
            "lat": 28.594097,
            "lng": 77.341238
        },
        {
            "lat": 28.594173,
            "lng": 77.341299
        },
        {
            "lat": 28.594399,
            "lng": 77.341212
        },
        {
            "lat": 28.594434,
            "lng": 77.341334
        },
        {
            "lat": 28.594407,
            "lng": 77.34176
        },
        {
            "lat": 28.594279,
            "lng": 77.341747
        },
        {
            "lat": 28.593866,
            "lng": 77.341303
        },
        {
            "lat": 28.593633,
            "lng": 77.341181
        },
        {
            "lat": 28.593496,
            "lng": 77.341268
        },
        {
            "lat": 28.592778,
            "lng": 77.341941
        },
        {
            "lat": 28.592048,
            "lng": 77.342623
        },
        {
            "lat": 28.591436,
            "lng": 77.3432
        },
        {
            "lat": 28.590595,
            "lng": 77.343965
        },
        {
            "lat": 28.590108,
            "lng": 77.344413
        },
        {
            "lat": 28.589481,
            "lng": 77.344993
        },
        {
            "lat": 28.588777,
            "lng": 77.345666
        },
        {
            "lat": 28.588071,
            "lng": 77.346333
        },
        {
            "lat": 28.58742,
            "lng": 77.346953
        },
        {
            "lat": 28.586606,
            "lng": 77.347717
        },
        {
            "lat": 28.585819,
            "lng": 77.348453
        },
        {
            "lat": 28.585418,
            "lng": 77.348857
        },
        {
            "lat": 28.584664,
            "lng": 77.349556
        },
        {
            "lat": 28.583819,
            "lng": 77.350363
        },
        {
            "lat": 28.582885,
            "lng": 77.351224
        },
        {
            "lat": 28.582043,
            "lng": 77.351985
        },
        {
            "lat": 28.581367,
            "lng": 77.352636
        },
        {
            "lat": 28.580947,
            "lng": 77.352956
        },
        {
            "lat": 28.579988,
            "lng": 77.353668
        },
        {
            "lat": 28.578882,
            "lng": 77.354573
        },
        {
            "lat": 28.577835,
            "lng": 77.355421
        },
        {
            "lat": 28.576834,
            "lng": 77.356234
        },
        {
            "lat": 28.575818,
            "lng": 77.357053
        },
        {
            "lat": 28.574961,
            "lng": 77.357765
        },
        {
            "lat": 28.574008,
            "lng": 77.358524
        },
        {
            "lat": 28.573159,
            "lng": 77.359204
        },
        {
            "lat": 28.568067,
            "lng": 77.363539
        },
        {
            "lat": 28.566384,
            "lng": 77.364704
        },
        {
            "lat": 28.565637,
            "lng": 77.365207
        },
        {
            "lat": 28.56484,
            "lng": 77.365587
        },
        {
            "lat": 28.564041,
            "lng": 77.365799
        },
        {
            "lat": 28.563288,
            "lng": 77.36586
        },
        {
            "lat": 28.562052,
            "lng": 77.365792
        },
        {
            "lat": 28.560956,
            "lng": 77.365833
        },
        {
            "lat": 28.560908,
            "lng": 77.365981
        },
        {
            "lat": 28.560904,
            "lng": 77.366279
        },
        {
            "lat": 28.560887,
            "lng": 77.367434
        },
        {
            "lat": 28.560818,
            "lng": 77.367877
        },
        {
            "lat": 28.560763,
            "lng": 77.367839
        },
        {
            "lat": 28.560679,
            "lng": 77.367729
        },
        {
            "lat": 28.560588,
            "lng": 77.367685
        },
        {
            "lat": 28.560118,
            "lng": 77.367622
        },
        {
            "lat": 28.55898,
            "lng": 77.367492
        },
        {
            "lat": 28.558063,
            "lng": 77.367463
        },
        {
            "lat": 28.557747,
            "lng": 77.367603
        },
        {
            "lat": 28.557461,
            "lng": 77.367919
        },
        {
            "lat": 28.557376,
            "lng": 77.368003
        },
        {
            "lat": 28.557319,
            "lng": 77.368008
        },
        {
            "lat": 28.55731,
            "lng": 77.368001
        },
        {
            "lat": 28.557694,
            "lng": 77.367599
        },
        {
            "lat": 28.557628,
            "lng": 77.367579
        },
        {
            "lat": 28.557714,
            "lng": 77.367525
        },
        {
            "lat": 28.557883,
            "lng": 77.367453
        },
        {
            "lat": 28.558146,
            "lng": 77.367381
        },
        {
            "lat": 28.559325,
            "lng": 77.367457
        },
        {
            "lat": 28.560471,
            "lng": 77.367489
        },
        {
            "lat": 28.560553,
            "lng": 77.367321
        },
        {
            "lat": 28.560645,
            "lng": 77.36645
        },
        {
            "lat": 28.560625,
            "lng": 77.365774
        },
        {
            "lat": 28.560513,
            "lng": 77.365622
        },
        {
            "lat": 28.560397,
            "lng": 77.365594
        },
        {
            "lat": 28.559247,
            "lng": 77.365511
        },
        {
            "lat": 28.558048,
            "lng": 77.365401
        },
        {
            "lat": 28.557337,
            "lng": 77.365334
        },
        {
            "lat": 28.557254,
            "lng": 77.365257
        },
        {
            "lat": 28.557286,
            "lng": 77.365242
        },
        {
            "lat": 28.558337,
            "lng": 77.36537
        },
        {
            "lat": 28.559589,
            "lng": 77.365476
        },
        {
            "lat": 28.560689,
            "lng": 77.365472
        },
        {
            "lat": 28.560751,
            "lng": 77.365398
        },
        {
            "lat": 28.560774,
            "lng": 77.365287
        },
        {
            "lat": 28.560798,
            "lng": 77.36408
        },
        {
            "lat": 28.560826,
            "lng": 77.362754
        },
        {
            "lat": 28.560932,
            "lng": 77.361617
        },
        {
            "lat": 28.56103,
            "lng": 77.360518
        },
        {
            "lat": 28.561271,
            "lng": 77.358492
        },
        {
            "lat": 28.561498,
            "lng": 77.354996
        },
        {
            "lat": 28.561579,
            "lng": 77.353439
        },
        {
            "lat": 28.561666,
            "lng": 77.352195
        },
        {
            "lat": 28.561497,
            "lng": 77.349638
        },
        {
            "lat": 28.561165,
            "lng": 77.348163
        },
        {
            "lat": 28.560957,
            "lng": 77.346867
        },
        {
            "lat": 28.56084,
            "lng": 77.346202
        },
        {
            "lat": 28.560772,
            "lng": 77.3461
        },
        {
            "lat": 28.560601,
            "lng": 77.346101
        },
        {
            "lat": 28.560547,
            "lng": 77.346121
        },
        {
            "lat": 28.559912,
            "lng": 77.346323
        },
        {
            "lat": 28.559753,
            "lng": 77.346351
        },
        {
            "lat": 28.559626,
            "lng": 77.346362
        },
        {
            "lat": 28.558748,
            "lng": 77.346516
        },
        {
            "lat": 28.557643,
            "lng": 77.346419
        },
        {
            "lat": 28.55763,
            "lng": 77.346296
        },
        {
            "lat": 28.557728,
            "lng": 77.3459
        },
        {
            "lat": 28.557919,
            "lng": 77.345353
        },
        {
            "lat": 28.557898,
            "lng": 77.345293
        },
        {
            "lat": 28.557827,
            "lng": 77.345261
        },
        {
            "lat": 28.55775,
            "lng": 77.34519
        },
        {
            "lat": 28.557654,
            "lng": 77.345987
        },
        {
            "lat": 28.557909,
            "lng": 77.345105
        },
        {
            "lat": 28.557962,
            "lng": 77.345237
        },
        {
            "lat": 28.557843,
            "lng": 77.345472
        },
        {
            "lat": 28.557497,
            "lng": 77.346103
        },
        {
            "lat": 28.557501,
            "lng": 77.34617
        },
        {
            "lat": 28.557632,
            "lng": 77.346366
        },
        {
            "lat": 28.558695,
            "lng": 77.346495
        },
        {
            "lat": 28.559039,
            "lng": 77.346496
        },
        {
            "lat": 28.559969,
            "lng": 77.346232
        },
        {
            "lat": 28.560657,
            "lng": 77.346011
        },
        {
            "lat": 28.560804,
            "lng": 77.34568
        },
        {
            "lat": 28.560687,
            "lng": 77.344479
        },
        {
            "lat": 28.560507,
            "lng": 77.343362
        },
        {
            "lat": 28.560378,
            "lng": 77.342307
        },
        {
            "lat": 28.560418,
            "lng": 77.341466
        },
        {
            "lat": 28.560618,
            "lng": 77.340749
        },
        {
            "lat": 28.560971,
            "lng": 77.34004
        },
        {
            "lat": 28.561852,
            "lng": 77.338823
        },
        {
            "lat": 28.562579,
            "lng": 77.337745
        },
        {
            "lat": 28.563347,
            "lng": 77.336623
        },
        {
            "lat": 28.564005,
            "lng": 77.335685
        },
        {
            "lat": 28.564644,
            "lng": 77.334756
        },
        {
            "lat": 28.565384,
            "lng": 77.333647
        },
        {
            "lat": 28.566126,
            "lng": 77.332567
        },
        {
            "lat": 28.566753,
            "lng": 77.331361
        },
        {
            "lat": 28.568502,
            "lng": 77.32884
        },
        {
            "lat": 28.569191,
            "lng": 77.327933
        },
        {
            "lat": 28.569627,
            "lng": 77.327307
        },
        {
            "lat": 28.569303,
            "lng": 77.326487
        },
        {
            "lat": 28.568656,
            "lng": 77.325673
        },
        {
            "lat": 28.567773,
            "lng": 77.324602
        },
        {
            "lat": 28.567013,
            "lng": 77.323564
        },
        {
            "lat": 28.566275,
            "lng": 77.322432
        },
        {
            "lat": 28.566109,
            "lng": 77.322058
        },
        {
            "lat": 28.565788,
            "lng": 77.320731
        },
        {
            "lat": 28.565568,
            "lng": 77.319459
        },
        {
            "lat": 28.565313,
            "lng": 77.317797
        },
        {
            "lat": 28.565046,
            "lng": 77.316089
        },
        {
            "lat": 28.564799,
            "lng": 77.314782
        },
        {
            "lat": 28.564705,
            "lng": 77.314573
        },
        {
            "lat": 28.564577,
            "lng": 77.314406
        },
        {
            "lat": 28.56441,
            "lng": 77.314283
        },
        {
            "lat": 28.564208,
            "lng": 77.314233
        },
        {
            "lat": 28.563993,
            "lng": 77.314262
        },
        {
            "lat": 28.563721,
            "lng": 77.314461
        },
        {
            "lat": 28.563612,
            "lng": 77.314668
        },
        {
            "lat": 28.563577,
            "lng": 77.314915
        },
        {
            "lat": 28.563623,
            "lng": 77.315159
        },
        {
            "lat": 28.563728,
            "lng": 77.315364
        },
        {
            "lat": 28.563888,
            "lng": 77.315509
        },
        {
            "lat": 28.56408,
            "lng": 77.315581
        },
        {
            "lat": 28.564369,
            "lng": 77.315541
        },
        {
            "lat": 28.565056,
            "lng": 77.315134
        },
        {
            "lat": 28.566171,
            "lng": 77.314357
        },
        {
            "lat": 28.567207,
            "lng": 77.313639
        },
        {
            "lat": 28.568393,
            "lng": 77.312746
        },
        {
            "lat": 28.569377,
            "lng": 77.312029
        },
        {
            "lat": 28.570226,
            "lng": 77.311395
        },
        {
            "lat": 28.570973,
            "lng": 77.310837
        },
        {
            "lat": 28.572074,
            "lng": 77.310012
        },
        {
            "lat": 28.573065,
            "lng": 77.309177
        },
        {
            "lat": 28.574342,
            "lng": 77.308286
        },
        {
            "lat": 28.575726,
            "lng": 77.307273
        },
        {
            "lat": 28.576944,
            "lng": 77.306361
        },
        {
            "lat": 28.578212,
            "lng": 77.305416
        },
        {
            "lat": 28.579316,
            "lng": 77.30461
        },
        {
            "lat": 28.58025,
            "lng": 77.303944
        },
        {
            "lat": 28.581383,
            "lng": 77.303079
        },
        {
            "lat": 28.582479,
            "lng": 77.30225
        },
        {
            "lat": 28.583367,
            "lng": 77.301585
        },
        {
            "lat": 28.584189,
            "lng": 77.300944
        },
        {
            "lat": 28.584696,
            "lng": 77.300476
        },
        {
            "lat": 28.585104,
            "lng": 77.299983
        },
        {
            "lat": 28.585558,
            "lng": 77.299201
        },
        {
            "lat": 28.585721,
            "lng": 77.298969
        },
        {
            "lat": 28.586293,
            "lng": 77.298332
        },
        {
            "lat": 28.587022,
            "lng": 77.297779
        },
        {
            "lat": 28.587549,
            "lng": 77.297527
        },
        {
            "lat": 28.587613,
            "lng": 77.297512
        },
        {
            "lat": 28.588556,
            "lng": 77.297029
        },
        {
            "lat": 28.589528,
            "lng": 77.296486
        },
        {
            "lat": 28.590771,
            "lng": 77.295776
        },
        {
            "lat": 28.59207,
            "lng": 77.294968
        },
        {
            "lat": 28.593291,
            "lng": 77.294212
        },
        {
            "lat": 28.594659,
            "lng": 77.293437
        },
        {
            "lat": 28.596071,
            "lng": 77.292713
        },
        {
            "lat": 28.598605,
            "lng": 77.291411
        },
        {
            "lat": 28.600019,
            "lng": 77.290683
        },
        {
            "lat": 28.601344,
            "lng": 77.290011
        },
        {
            "lat": 28.602564,
            "lng": 77.289391
        },
        {
            "lat": 28.603982,
            "lng": 77.288676
        },
        {
            "lat": 28.605251,
            "lng": 77.288031
        },
        {
            "lat": 28.606604,
            "lng": 77.287347
        },
        {
            "lat": 28.607796,
            "lng": 77.286763
        },
        {
            "lat": 28.6088,
            "lng": 77.286196
        },
        {
            "lat": 28.609937,
            "lng": 77.285443
        },
        {
            "lat": 28.610918,
            "lng": 77.284819
        },
        {
            "lat": 28.611949,
            "lng": 77.284142
        },
        {
            "lat": 28.613151,
            "lng": 77.283316
        },
        {
            "lat": 28.614193,
            "lng": 77.282489
        },
        {
            "lat": 28.615298,
            "lng": 77.281782
        },
        {
            "lat": 28.616483,
            "lng": 77.280997
        },
        {
            "lat": 28.617483,
            "lng": 77.280357
        },
        {
            "lat": 28.618296,
            "lng": 77.279809
        },
        {
            "lat": 28.61905,
            "lng": 77.279324
        },
        {
            "lat": 28.620106,
            "lng": 77.278694
        },
        {
            "lat": 28.621183,
            "lng": 77.277941
        },
        {
            "lat": 28.622166,
            "lng": 77.277263
        },
        {
            "lat": 28.623248,
            "lng": 77.276558
        },
        {
            "lat": 28.624103,
            "lng": 77.275963
        },
        {
            "lat": 28.624844,
            "lng": 77.275478
        },
        {
            "lat": 28.625842,
            "lng": 77.274731
        },
        {
            "lat": 28.626245,
            "lng": 77.274204
        },
        {
            "lat": 28.627043,
            "lng": 77.272607
        },
        {
            "lat": 28.627509,
            "lng": 77.271943
        },
        {
            "lat": 28.628002,
            "lng": 77.271567
        },
        {
            "lat": 28.62857,
            "lng": 77.271312
        },
        {
            "lat": 28.629155,
            "lng": 77.271252
        },
        {
            "lat": 28.630587,
            "lng": 77.271353
        },
        {
            "lat": 28.633503,
            "lng": 77.269912
        },
        {
            "lat": 28.634462,
            "lng": 77.269838
        },
        {
            "lat": 28.635609,
            "lng": 77.269682
        },
        {
            "lat": 28.636721,
            "lng": 77.269602
        },
        {
            "lat": 28.637768,
            "lng": 77.269471
        },
        {
            "lat": 28.638872,
            "lng": 77.269347
        },
        {
            "lat": 28.639923,
            "lng": 77.269229
        },
        {
            "lat": 28.64082,
            "lng": 77.269188
        },
        {
            "lat": 28.643381,
            "lng": 77.269004
        },
        {
            "lat": 28.644584,
            "lng": 77.268892
        },
        {
            "lat": 28.646056,
            "lng": 77.268743
        },
        {
            "lat": 28.647992,
            "lng": 77.268342
        },
        {
            "lat": 28.648368,
            "lng": 77.268078
        },
        {
            "lat": 28.648646,
            "lng": 77.267742
        },
        {
            "lat": 28.650664,
            "lng": 77.266819
        },
        {
            "lat": 28.651026,
            "lng": 77.266683
        },
        {
            "lat": 28.651308,
            "lng": 77.266388
        },
        {
            "lat": 28.651414,
            "lng": 77.266196
        },
        {
            "lat": 28.651528,
            "lng": 77.265768
        },
        {
            "lat": 28.651361,
            "lng": 77.256824
        },
        {
            "lat": 28.651338,
            "lng": 77.255542
        },
        {
            "lat": 28.651313,
            "lng": 77.25426
        },
        {
            "lat": 28.651282,
            "lng": 77.252973
        },
        {
            "lat": 28.651249,
            "lng": 77.251676
        },
        {
            "lat": 28.651206,
            "lng": 77.250898
        },
        {
            "lat": 28.651154,
            "lng": 77.250777
        },
        {
            "lat": 28.650879,
            "lng": 77.250434
        },
        {
            "lat": 28.650707,
            "lng": 77.25036
        },
        {
            "lat": 28.65053,
            "lng": 77.250351
        },
        {
            "lat": 28.650353,
            "lng": 77.250396
        },
        {
            "lat": 28.650191,
            "lng": 77.250494
        },
        {
            "lat": 28.650056,
            "lng": 77.250647
        },
        {
            "lat": 28.649969,
            "lng": 77.250846
        },
        {
            "lat": 28.650732,
            "lng": 77.251745
        },
        {
            "lat": 28.65182,
            "lng": 77.25106
        },
        {
            "lat": 28.652936,
            "lng": 77.250335
        },
        {
            "lat": 28.653885,
            "lng": 77.249704
        },
        {
            "lat": 28.654948,
            "lng": 77.249133
        },
        {
            "lat": 28.656126,
            "lng": 77.248631
        },
        {
            "lat": 28.656797,
            "lng": 77.248472
        },
        {
            "lat": 28.657922,
            "lng": 77.248406
        },
        {
            "lat": 28.658097,
            "lng": 77.248368
        },
        {
            "lat": 28.658255,
            "lng": 77.248289
        },
        {
            "lat": 28.658401,
            "lng": 77.248184
        },
        {
            "lat": 28.658829,
            "lng": 77.247673
        },
        {
            "lat": 28.659466,
            "lng": 77.246672
        },
        {
            "lat": 28.659513,
            "lng": 77.246444
        },
        {
            "lat": 28.659511,
            "lng": 77.246109
        },
        {
            "lat": 28.659299,
            "lng": 77.245078
        },
        {
            "lat": 28.65912,
            "lng": 77.244387
        },
        {
            "lat": 28.659117,
            "lng": 77.244245
        },
        {
            "lat": 28.659136,
            "lng": 77.244112
        },
        {
            "lat": 28.659179,
            "lng": 77.243968
        },
        {
            "lat": 28.659376,
            "lng": 77.243607
        },
        {
            "lat": 28.659893,
            "lng": 77.242762
        },
        {
            "lat": 28.660418,
            "lng": 77.241936
        },
        {
            "lat": 28.660938,
            "lng": 77.241078
        },
        {
            "lat": 28.661479,
            "lng": 77.240237
        },
        {
            "lat": 28.661873,
            "lng": 77.2393
        },
        {
            "lat": 28.662274,
            "lng": 77.238339
        },
        {
            "lat": 28.662877,
            "lng": 77.237199
        },
        {
            "lat": 28.663327,
            "lng": 77.236327
        },
        {
            "lat": 28.663665,
            "lng": 77.235844
        },
        {
            "lat": 28.664012,
            "lng": 77.235373
        },
        {
            "lat": 28.664009,
            "lng": 77.235292
        },
        {
            "lat": 28.663921,
            "lng": 77.235178
        },
        {
            "lat": 28.663186,
            "lng": 77.234632
        },
        {
            "lat": 28.662596,
            "lng": 77.234228
        },
        {
            "lat": 28.662517,
            "lng": 77.234293
        },
        {
            "lat": 28.662434,
            "lng": 77.234462
        },
        {
            "lat": 28.66192,
            "lng": 77.235157
        },
        {
            "lat": 28.661457,
            "lng": 77.235782
        },
        {
            "lat": 28.661297,
            "lng": 77.235867
        },
        {
            "lat": 28.660543,
            "lng": 77.236078
        },
        {
            "lat": 28.66027,
            "lng": 77.236149
        },
        {
            "lat": 28.660192,
            "lng": 77.236116
        },
        {
            "lat": 28.660144,
            "lng": 77.23605
        },
        {
            "lat": 28.660128,
            "lng": 77.235965
        },
        {
            "lat": 28.660142,
            "lng": 77.235849
        },
        {
            "lat": 28.660208,
            "lng": 77.234729
        },
        {
            "lat": 28.660245,
            "lng": 77.233519
        },
        {
            "lat": 28.660287,
            "lng": 77.232301
        },
        {
            "lat": 28.660293,
            "lng": 77.232053
        },
        {
            "lat": 28.660255,
            "lng": 77.231724
        },
        {
            "lat": 28.659947,
            "lng": 77.231645
        },
        {
            "lat": 28.659687,
            "lng": 77.231689
        },
        {
            "lat": 28.659316,
            "lng": 77.231686
        },
        {
            "lat": 28.658735,
            "lng": 77.231677
        },
        {
            "lat": 28.658358,
            "lng": 77.231726
        },
        {
            "lat": 28.657998,
            "lng": 77.231776
        },
        {
            "lat": 28.657833,
            "lng": 77.231814
        },
        {
            "lat": 28.657672,
            "lng": 77.231786
        },
        {
            "lat": 28.657374,
            "lng": 77.231695
        },
        {
            "lat": 28.65713,
            "lng": 77.231711
        },
        {
            "lat": 28.657085,
            "lng": 77.231575
        },
        {
            "lat": 28.657164,
            "lng": 77.230572
        },
        {
            "lat": 28.65724,
            "lng": 77.230519
        },
        {
            "lat": 28.657423,
            "lng": 77.230479
        },
        {
            "lat": 28.658476,
            "lng": 77.230638
        },
        {
            "lat": 28.658304,
            "lng": 77.23032
        },
        {
            "lat": 28.658378,
            "lng": 77.230376
        },
        {
            "lat": 28.658646,
            "lng": 77.231237
        },
        {
            "lat": 28.658568,
            "lng": 77.231449
        },
        {
            "lat": 28.658532,
            "lng": 77.230908
        },
        {
            "lat": 28.658805,
            "lng": 77.231519
        },
        {
            "lat": 28.659649,
            "lng": 77.231489
        },
        {
            "lat": 28.660095,
            "lng": 77.231436
        },
        {
            "lat": 28.660352,
            "lng": 77.231788
        },
        {
            "lat": 28.660348,
            "lng": 77.231948
        },
        {
            "lat": 28.660103,
            "lng": 77.237501
        },
        {
            "lat": 28.660125,
            "lng": 77.237843
        },
        {
            "lat": 28.660165,
            "lng": 77.237923
        },
        {
            "lat": 28.661085,
            "lng": 77.244772
        },
        {
            "lat": 28.660572,
            "lng": 77.245335
        },
        {
            "lat": 28.659935,
            "lng": 77.246325
        },
        {
            "lat": 28.658888,
            "lng": 77.247856
        },
        {
            "lat": 28.657725,
            "lng": 77.248577
        },
        {
            "lat": 28.655848,
            "lng": 77.248944
        },
        {
            "lat": 28.654603,
            "lng": 77.24958
        },
        {
            "lat": 28.653545,
            "lng": 77.250375
        },
        {
            "lat": 28.660311,
            "lng": 77.233104
        },
        {
            "lat": 28.660218,
            "lng": 77.23535
        },
        {
            "lat": 28.660193,
            "lng": 77.236243
        },
        {
            "lat": 28.602817,
            "lng": 77.289383
        },
        {
            "lat": 28.597197,
            "lng": 77.292209
        },
        {
            "lat": 28.595831,
            "lng": 77.292901
        },
        {
            "lat": 28.586959,
            "lng": 77.298014
        },
        {
            "lat": 28.585707,
            "lng": 77.299318
        },
        {
            "lat": 28.584651,
            "lng": 77.300721
        },
        {
            "lat": 28.662479,
            "lng": 77.242272
        },
        {
            "lat": 28.662362,
            "lng": 77.243644
        },
        {
            "lat": 28.662302,
            "lng": 77.243862
        },
        {
            "lat": 28.656564,
            "lng": 77.248708
        },
        {
            "lat": 28.570732,
            "lng": 77.311124
        },
        {
            "lat": 28.559892,
            "lng": 77.333852
        },
        {
            "lat": 28.560595,
            "lng": 77.335631
        },
        {
            "lat": 28.561267,
            "lng": 77.337317
        },
        {
            "lat": 28.560603,
            "lng": 77.342596
        },
        {
            "lat": 28.560787,
            "lng": 77.343881
        },
        {
            "lat": 28.560951,
            "lng": 77.345244
        },
        {
            "lat": 28.65162,
            "lng": 77.253798
        },
        {
            "lat": 28.651539,
            "lng": 77.253965
        }
	];
	var icon_url ="https://apis.mapmyindia.com/map_v3/1.png";

	polylineObject = mapplsClassObject.Polyline(
	{
		map:  mapObject,
		paths:  pts,
		strokeColor:  'red',
		strokeOpacity:  1.0,
	    strokeWeight: 5,
          fitbounds: false,
		animate: {
            icon_width: 20,
            icon_height: 50,
          },
	  }
);
		//load map layers/components after map load, inside this callback (Recommended)
		mapObject.on("load", ()=>{
		// Activites after mapload
		})

	});



return (
<div>
<div  id="map"  style={styleMap}></div>
</div>
);
}
export  default  Polyline;