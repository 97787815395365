import React, { useEffect, useState, useRef } from 'react';
import { mappls } from 'mappls-web-maps';
import configData from '../config.json';
import axios from 'axios';

async function getLatLong(imei) {
    try {
        const constURL = `${configData.API_URL_LOCAL}/api/livemap/1/${imei}`;
        const response = await axios.get(constURL);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}

function PolylineMapV2({ data, imei }) {
    const [markerObject, setMarkerObject] = useState(null);
    const intervalIdRef = useRef(null);

    useEffect(() => {
        const initializeMap = async () => {
            const mapplsClassObject = new mappls();
            await mapplsClassObject.initialize(configData.MAP_TOKEN);

            const firstValueLat = data[0].lat;
            const firstValueLng = data[0].lng;

            const mapProps = {
                center: [firstValueLat, firstValueLng],
                zoom: 15
            };

            const mapObject = mapplsClassObject.Map({ id: 'map', properties: mapProps });

            const marker = mapplsClassObject.Marker({
                map: mapObject,
                position: { lat: firstValueLat, lng: firstValueLng },
                fitbounds: false,
                icon: 'https://apis.mapmyindia.com/map_v3/2.png'
            });

            setMarkerObject(marker);

            mapObject.on('load', () => {
                intervalIdRef.current = setInterval(async () => {
                    const response = await getLatLong(imei);
                    if (response) {
                        marker.setPosition({ lat: response.data[0].lat, lng: response.data[0].lng });
                        marker.setIcon('http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png');
                    }
                }, 10000);
            });
        };

        initializeMap();

        return () => {
            // Clear interval when component unmounts
            clearInterval(intervalIdRef.current);
        };
    }, []); // Empty dependency array to run effect only once

    return (
        <div>
            <div id="map" style={{ width: '99%', height: '99vh', display: 'inline-block' }}></div>
        </div>
    );
}

export default PolylineMapV2;
